<template>
  <div class="bg-white px-8 pt-10 pb-20 lg:px-20">
    <validation-observer v-slot="{ invalid }">
    <h1 class="font-poppins-medium text-xl lg:text-2xl text-center text-theme-2">Talk to our data experts about
      how to get <br class="hidden lg:block">your Big Data ready for analytics and AI.</h1>
    <div class="lg:grid lg:grid-cols-2 mt-5 lg:mt-10 lg:gap-x-10">
      <div class="lg:col-start-1 mt-4 lg:mt-0">
        <validation-provider rules='required' :custom-messages="{ required: 'first name field can not be empty!' }" v-slot="{ errors }">
        <input name="firstname" v-model="form.first_name" placeholder="NAME" type="text" class="peer h-14 lg:h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg">
          <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
        </validation-provider>
      </div>
      <div class="lg:col-start-2 mt-4 lg:mt-0">
        <input name="companyName" v-model="form.company_name" placeholder="COMPANY" type="text" class="peer h-14 lg:h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg">
      </div>
    <div class="mt-4 lg:mt-10 lg:col-start-1 lg:row-start-2 lg:gap-x-10 lg:col-span-1">
      <validation-provider rules='required|email' :custom-messages="{ required: 'Please use a valid email address!' }" v-slot="{ errors }">
      <input name="email" v-model="form.company_email" type="email" placeholder="WORK EMAIL" class="peer h-14 lg:h-10 w-full rounded-md px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg">
        <span class='error block left-2 top-full mt-1 text-red-600' style="font-size: 10px">{{ errors[0] }}</span>
      </validation-provider>
    </div>
    </div>
    <div class="mt-10 mx-auto">
      <button :disabled="invalid" :class="[!invalid ? 'cursor-pointer' : 'cursor-not-allowed opacity-30']" @click="submitForm" class="flex contact text-theme-2 bg-transparent border-2 border-theme-2 mx-auto lg:mr-auto hover:bg-theme-2 hover:text-white lg:mx-0 h-12 p-3 lg:px-20 px-20 text-xl font-poppins-regular items-center text-base">
        <div class="elasticball" v-if="loading">
          <div class="elasticball__ball"><i></i></div>
        </div>
        <span v-if="!loading">Send</span>
      </button>
    </div>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        company_name: "",
        company_email: "",
        phone: ""
      },
      loading: false
    }
  },
  components: {ValidationObserver, ValidationProvider},
  methods: {
    submitForm() {
      const data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        company_name: this.form.company_name,
        company_email: this.form.company_email,
        phone: this.form.phone,
        page: "Hilo Data"
      }
      this.loading = true
      this.$store.dispatch("contact/contactSalesAction", data).then(() => {
        this.$toast("Your information has been submitted!", {
          timeout: 5000,
          position: "top-right",
          hideProgressBar: true,
          type: "info",
          maxToasts: "1",
          closeButton: false,
          toastClassName: "my-custom-toast-class",
        });
        this.loading = false
      })
      this.form = {}
    }
  }
}
</script>
<style lang="scss" scoped>
.peer {
  background: #e9e9e9 !important;
  border-radius: 5px !important;
  color: black;
}
$primary: #c6c0ba;
$secondary: #333333;
$third: #000000;
// @ELASTIC BALL
div.elasticball {
  display: flex;
  justify-content: center;
  position: relative;
  height: 2rem;

  &:before {
    content: '';
    width: 2rem;
    height: 2rem;
    bottom: 0;
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    background-color: $secondary;
    transform: scaleY(0.5) translateY(1rem);
    animation: elasticball-bottom .5s .3s infinite;
  }

  &__ball {
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: 50% 100%;
    animation: elasticball .5s infinite cubic-bezier(1,-0.01,0,1);

    i {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      background-color: $secondary;

      animation: elasticball-color 2s infinite;
    }
  }
}
@keyframes elasticball {
  0% {
    transform: translateY(0) scaleY(1)
  } 50% {
      transform: translateY(1rem) scaleY(.7)
    } 100% {
        transform: translateY(0) scaleY(1)
      }
}
@keyframes elasticball-color {
  0% {
    background-color: $secondary;
  } 30% {
      background-color: $secondary;
    } 50% {
        background-color: $third;
      } 80% {
          background-color: $third;
        }
}
@keyframes elasticball-bottom {
  0% {
    transform: scale(1, 0.5) translateY(1rem);
    opacity: 0.3;
  } 100% {
      transform: scale(2, 0.5) translateY(1rem);
      opacity: 0;
    }
}
</style>
