<template>
  <div class="pb-20 bg-theme-2 pt-20 lg:py-28">
    <h1 class="font-poppins-medium text-3xl lg:text-4xl mx-8 text-white text-center lg:font-semibold">They trusted us,<br class="lg:hidden"> We delivered.</h1>
    <div class="w-full lg:flex lg:px-10 xl:px-32 lg:gap-x-2 xl:gap-x-5">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/Inditex-logo-2.png" alt="Inditex">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/efti1.png" alt="efti">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/pullmantur-logo-700x200.png" alt="pullmantur">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/mr-checo-logo-03-07.png" alt="mrCheco">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/NN-01.png" alt="nommonammi">
      <img class="mt-14 flex justify-center mx-auto lg:object-contain" src="../assets/logos/NUEVO-LOGO___Mesa-de-trabajo-1.png" alt="growgood">
    </div>
  </div>
</template>
<script>
export default {
  name: "TrustedUs"
}
</script>
