<template>
  <div class="pt-20 pb-20 bg-theme-2 px-4 lg:px-20 2xl:px-32">
    <div>
      <h1 class="title font-poppins-medium text-3xl lg:text-4xl mx-8 text-black text-center text-white">Leverage your
        <br class="lg:hidden"> data to the hilt</h1>
      <h4 class="text-black mx-8 mt-4 font-poppins-regular text-base lg:text-xl lg:w-1/2 text-center lg:mx-auto text-white"> We help organisations make smart data-driven decisions by translating
        their data into meaningful and
        actionable information.</h4>
    </div>
    <div class="lg:flex w-2/2 lg:gap-x-10 xl:gap-x-5 lg:items-center mt-10">
      <div class="mt-4 flex justify-center lg:w-1/2 lg:h-72 lg:block">
        <img class="lg:hidden" src="../assets/images/Objeto-mobile.png" alt="Objeto">
        <img class="lg:block hidden h-60 xl:h-72 2xl:h-80 w-full object-contain" src="../assets/images/Objeto-desktop.png" alt="Objeto">
      </div>
      <div class="lg:w-1/2">
        <div class="box flex w-4/4 lg:w-11/12 lg:grid lg:grid-cols-8 lg:gap-x-4 px-5 py-4 mt-20 lg:mt-4 lg:-ml-10">
          <div class="w-1/4 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/research-mob.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/research-desk.png" alt="research">
          </div>
          <div class="w-3/4 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base">Data Integration</h2>
            <p class="font-poppins-regular text-xs mt-3">The potential of AI and analytics
              is greatly increased when the breadth of data sources are brought together. We develop Big Data delivery systems for companies with large data sets to measure AI and analytics-ready data in near real time. </p>
          </div>
        </div>
        <div class="box flex w-4/4 lg:grid lg:grid-cols-8 lg:gap-x-4 px-5 py-4 mt-10 lg:mt-5">
          <div class="w-1/4 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/noun-mob.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/noun-desk.png" alt="research">
          </div>
          <div class="w-3/4 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base">Data Warehousing</h2>
            <p class="font-poppins-regular text-xs mt-3">Consolidate all your data into a single BigQuery warehouse, making it more accessible and easy to analyze. Designed to enable and support business intelligence (BI) activities, especially analytics, for better analysis, report and informed decisions.
            </p>
          </div>
        </div>
        <div class="box flex w-4/4 lg:w-11/12 lg:grid lg:grid-cols-8 lg:gap-x-4 px-5 py-4 mt-10 lg:mt-5 lg:-ml-10">
          <div class="w-1/4 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/test-mob.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/test-desk.png" alt="research">
          </div>
          <div class="w-3/4 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base">Big Data</h2>
            <p class="font-poppins-regular text-xs mt-3">Collate your business data whether it's raw, organized or unorganized across multiple cloud vendors to enable a data foundation that enables real-time operational efficiencies, fuels your enterprise with advanced analytics, and supports mission-critical applications & tools while cutting infrastructure costs.</p>
          </div>
        </div>
        <div class="box flex w-4/4 lg:grid lg:grid-cols-8 lg:gap-x-4 px-5 py-4 mt-10 lg:mt-5">
          <div class="w-1/4 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/Elipse-mob.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/Elipse-desk.png" alt="research">
          </div>
          <div class="w-3/4 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base">Data Visualisation & BI</h2>
            <p class="font-poppins-regular text-xs mt-3">Create elegant and compelling visuals that are designed to display and instantly communicate the value in your data. Helping define Business Intelligence processes & roadmaps for driving strategic business decisions with fact-based insights to help attain your goals.</p>
          </div>
        </div>
        <div class="box flex lg:w-11/12 w-4/4 lg:grid lg:grid-cols-8 lg:gap-x-4 px-5 py-4 mt-10 lg:mt-5 lg:-ml-10">
          <div class="w-1/4 lg:w-full lg:col-start-1 lg:col-span-2 2xl:col-span-1">
            <img class="lg:hidden" src="../assets/images/software-mob.png" alt="research">
            <img class="hidden lg:block h-28 w-28 object-contain" src="../assets/images/software-desk.png" alt="research">
          </div>
          <div class="w-3/4 lg:w-full lg:col-start-3 lg:col-span-6 2xl:col-start-2">
            <h2 class="font-poppins-medium text-base">Software Solutions</h2>
            <p class="font-poppins-regular text-xs mt-3">Customized big data applications designed to investigate, analyze, and manage a variety of data based on your business needs. These apps can work with streaming, conventional, and atypical data types.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeverageData"
}
</script>
<style lang="scss" scoped>
.box {
  box-shadow: -19px 5px 25.759999999999998px 2.24px rgba(51, 51, 51, 0.2);
  border-radius: 32px;
  background: white;
}
@media only screen and (min-width: 1024px) {
  .title {
    font-weight: 600;
  }
}
</style>
