<template>
  <div class="pt-10 pb-20 bg-white px-4 bg-white lg:pt-20 lg:px-20 2xl:px-32">
    <div class="flex justify-center mt-10 lg:hidden">
      <img src="../assets/images/bridge-mob.png" alt="">
    </div>
    <div class="lg:flex lg:2/2">
      <div class="lg:w-1/2 lg:flex lg:flex-col	lg:justify-center">
        <div class="mt-8">
          <h1 class="font-poppins-medium text-3xl mx-5 text-black text-center lg:text-left lg:text-4xl text-theme-2 lg:mx-0 font-semibold">Bridge the gap between data and decision making</h1>
        </div>
        <div class="mt-4">
          <p class="text-theme-2 text-center lg:text-left w-full font-poppins-regular text-xs lg:text-base px-5 lg:px-0">Our Data Analytics services help businesses unravel key insights & make smarter decisions by mapping all the information sources, offering a complete picture </p>
        </div>
        <div class="flex justify-center mt-6 lg:block">
          <button @click="showModal" class="flex contact text-theme-2 bg-transparent border-2 border-theme-2 h-12 p-6 lg:px-14 text-lg font-poppins-regular items-center hover:bg-theme-2 hover:text-white">
            Get Started
          </button>
        </div>
      </div>
      <div class="lg:flex justify-center hidden lg:w-1/2">
        <img class="lg:h-72 2xl:h-full" src="../assets/images/bridge-desk.png" alt="">
      </div>
    </div>
    <ContactModal ref="dialog" />
  </div>
</template>
<script>
import ContactModal from "@/components/ContactModal";
export default {
  name: "BridgeTheGap",
  components: {ContactModal},
  methods: {
    showModal() {
      this.$refs.dialog.dialog = true
    }
  }
}
</script>
