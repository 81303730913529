<template>
  <div class="about lg:pt-10 pb-20 lg:w-2/2 lg:flex lg:mx-36 lg:items-center lg:mt-10">
    <div id="app">
      <lottie style="width: 100%" class="md:hidden" :options="defaultOptions" :height="300" :width="400" v-on:animCreated="handleAnimation"/>
      <lottie style="width: 100%" class="md:block hidden lg:hidden" :options="defaultOptions" :height="400" :width="400" v-on:animCreated="handleAnimation"/>
    </div>
    <div class="lg:w-1/2">
      <h1 class="font-poppins-medium text-3xl lg:text-5xl 2xl:text-6xl mx-8 lg:mx-0 text-theme-2">Grow your business <br> with the
        <br> right insights</h1>
      <h6 class="font-poppins-regular text-theme-2 mt-3 text-base mx-8 lg:mx-0">Data, Analytics, Visualisation & Consulting</h6>
      <button @click="showModal" class="flex contact text-theme-2 bg-transparent hover:bg-theme-2 hover:text-white border-4 lg:border border-theme-2 h-16 lg:h-12 p-3 lg:px-8 text-xl font-poppins-medium items-center mt-6 mx-8 px-8 lg:mx-0">
        Get Started
      </button>
    </div>
    <div class="hidden lg:block w-1/2">
      <lottie style="width: 100%; height: 100%" :options="defaultOptions" v-on:animCreated="handleAnimation"/>
    </div>
    <ContactModal ref="dialog" />
  </div>
</template>
<script>
import ContactModal from "@/components/ContactModal";
import lottie from 'vue-lottie';
export default {
  name: "About",
  components: {lottie, ContactModal},
  data() {
    return {
      defaultOptions: {
        animationData: require("../assets/json/lf30_editor_yjmu9eai.json"),
        autoplay: true
      },
      animationSpeed: 1
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    showModal() {
      this.$refs.dialog.dialog = true
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (min-width: 1024px) {
  .about {
    height: 75vh;
  }
}
</style>
