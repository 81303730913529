<template>
  <div class="bg-white">
    <Navbar />
    <div id="app" v-scroll-spy>
      <About id="one"/>
      <LeverageData id="two" />
      <BridgeTheGap id="three" />
      <TrustedUs id="four" />
      <ContactUs id="five" />
    </div>
    <Footer />
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import About from "@/components/About";
import LeverageData from "@/components/LeverageData";
import BridgeTheGap from "@/components/BridgeTheGap";
import TrustedUs from "@/components/TrustedUs";
import ContactUs from "@/components/ContactUs";
export default {
  name: 'App',
  components: {
    ContactUs,
    TrustedUs,
    BridgeTheGap,
    LeverageData,
    About,
    Footer,
    Navbar
  }
}
</script>

<style>
</style>
